<template>
  <div>
    <el-dropdown @command="command">
      <slot name="actions-trigger">
        <span class="el-dropdown-link more">
          <i class="eden-icon-more"></i>
        </span>
      </slot>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :icon="'eden-icon-eye'" :command="'view'"
          >View order detail</el-dropdown-item
        >
        <el-dropdown-item :icon="'eden-icon-edit'" :command="'form'"
          >Mark order status</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <eden-confirm-dialog
      title="Mark as complete?"
      button-text="Mark"
      button-type="success"
      :show.sync="showCompleting"
      @confirm="markOrder('complete')"
      :button-status="completing"
    >
      Are you sure you want to mark this order as complete?
    </eden-confirm-dialog>
    <eden-confirm-dialog
      title="Mark as pending?"
      button-text="Mark"
      button-type="success"
      :show.sync="showPending"
      @confirm="markOrder('pending')"
      :button-status="marking"
    >
      Are you sure you want to mark this order as pending?
    </eden-confirm-dialog>
    <order-details-dialog
      :order="order"
      :show.sync="showDetails"
      :marking="marking"
      :cancelling="cancelling"
      :packing="packing"
      @complete="markOrder('completed')"
      @cancel="cancelOrder"
    />
    <order-mark-status-dialog
      :orders="[order]"
      :show.sync="action.visibility"
      @success="emitUpdate"
    />
    <!-- <component
      :is="action.component"
      :action="'edit'"
      :show.sync="action.visibility"
      :mealItem="mealItem"
      @success="emitUpdate"
    /> -->
  </div>
</template>

<script>
import dfOrders from "@/requests/dance-festival/orders.js";
import OrderDetailsDialog from "./OrderDetailsDialog.vue";
import OrderMarkStatusDialog from "./OrderMarkStatusDialog.vue";

export default {
  components: { OrderDetailsDialog, OrderMarkStatusDialog },
  name: "OrderActions",
  props: {
    order: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      action: {
        component: "",
        command: "",
        visibility: false,
      },
      showCompleting: false,
      showPending: false,
      completing: false,
      marking: false,
      cancelling: false,
      packing: false,
      showDetails: false,
    };
  },
  computed: {
    componentVisibility() {
      return this.action.visibility;
    },
  },
  watch: {
    componentVisibility() {
      if (!this.action.visibility) {
        this.action.component = "";
      }
    },
  },
  methods: {
    command(command) {
      if (command === "view") {
        this.showDetails = true;
      } else if (command === "form") {
        this.action.visibility = true;
      }
    },
    emitUpdate() {
      this.$emit(
        "update",
        this.action.command === "form" ? "edit" : this.action.command,
      );
    },
    markOrder(status = "picked_up") {
      if (!status) return;

      this.marking = true;
      dfOrders
        .markAsCompleted({
          order_ids: [this.order.id],
          order_status: status,
        })
        .then(({ data }) => {
          if (data.status) {
            this.action.visibility = false;
            this.$message.success(data.message);
            this.emitUpdate();
          }
          this.marking = false;
          this.showDetails = false;
        })
        .catch((error) => {
          this.marking = false;
          this.showDetails = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    cancelOrder() {
      this.cancelling = true;
      dfOrders
        .markAsCompleted({
          order_ids: [this.order.id],
          order_status: "cancelled",
        })
        .then(({ data }) => {
          if (data.status) {
            this.action.visibility = false;
            this.$message.success(data.message);
            this.emitUpdate();
          }
          this.cancelling = false;
          this.showDetails = false;
        })
        .catch((error) => {
          this.cancelling = false;
          this.showDetails = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
