<template>
  <el-dialog
    title="Order Details"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="40% !important"
  >
    <el-row>
      <el-col :span="24">
        <div class="is-flex is-align-center is-justify-between">
          <div>
            <div class="font-lg text-primary">Order {{ order.id }}</div>
            <div class="mt-2">{{ order.order_id }}</div>
          </div>
          <div>
            <el-tag :type="setType(order.status)">
              {{ formatToTitleCase(order.status)?.replace("_", " ") }}
            </el-tag>
          </div>
        </div></el-col
      >
      <el-col :span="24">
        <eden-container class="mt-4">
          <div class="text-grey-primary text-bold mb-3">Personal Details</div>
          <el-row type="flex" :gutter="10">
            <el-col :span="8">
              <div class="text-grey-tertiary">Name</div>
              <div class="mt-1 text-grey-primary">
                {{ order.customer_name }}
              </div>
            </el-col>
            <el-col :span="8">
              <div class="text-grey-tertiary">Email</div>
              <div class="mt-1 text-grey-primary">
                {{ order.customer_email }}
              </div>
            </el-col>
            <el-col :span="8">
              <div class="text-grey-tertiary">Phone Number</div>
              <div class="mt-1 text-grey-primary">
                {{ order.customer_phone_number }}
              </div>
            </el-col>
          </el-row>
        </eden-container>
      </el-col>
      <el-col :span="24">
        <div class="text-grey-tertiary mt-4 text-bold">Order Item</div>
        <eden-container
          class="mt-2 text-grey-primary mb-2 is-flex"
          v-for="(item, ix) in order.meal_items"
          :key="ix"
        >
          {{ item.meal || "-" }}
          <el-tag class="ml-auto" :type="setType(item.status)">
            {{ formatToTitleCase(item.status)?.replace("_", " ") }}
          </el-tag>
        </eden-container>
      </el-col>
    </el-row>
    <span slot="footer" class="dialog-footer">
      <el-button
        :disabled="
          packing || marking || cancelling || order.status === 'cancelled'
        "
        :loading="cancelling"
        plain
        @click="$emit('cancel')"
        >Cancel Order</el-button
      >

      <el-button
        :type="'primary'"
        :loading="marking"
        :disabled="
          packing || marking || cancelling || order.status === 'picked_up'
        "
        @click="$emit('complete')"
        >Mark As Complete</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: "OrderDetailsDialog",
  props: {
    show: Boolean,
    marking: Boolean,
    cancelling: Boolean,
    packing: Boolean,
    order: Object,
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #21312a;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 5px;
}

p {
  font-size: 0.75rem;
  margin-bottom: 5px;
}

span {
  font-size: 0.875rem;
  color: #21312a;
  line-height: 1.5;
}

.el-tag {
  height: 26px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}
</style>
