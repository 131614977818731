export const mapOrdersFromBackend = (data) => {
  return data?.map((item) => ({
    id: item.id,
    order_id: item.order_number,
    customer_name: item.user_name,
    customer_email: item.user_email,
    order_date: item.created_at,
    customer_phone_number: item.phone_number,
    name: item?.meal_order_items_breakdown?.map((val) => val?.meal),
    meal_items: item?.meal_order_items_breakdown?.map((val) => ({
      meal: val?.meal,
      status: val?.status || "pending",
      quantity_ordered: val?.quantity_ordered || 0,
    })),
    status: item.order_status,
    created_at: item.created_at,
  }));
};

export const mapOrderFromBackend = (data) => {
  return {
    id: data.id,
    order_id: data.order_id,
    customer_name: data.user_name,
    customer_email: data.user_email,
    order_date: data.created_at,
    customer_phone_number: data.phone_number,
    name: data?.meal_order_items_breakdown?.map((val) => val?.meal),
    meal_items: data?.meal_order_items_breakdown?.map((val) => ({
      meal: val?.meal,
      status: val?.status || "pending",
      quantity_ordered: val?.quantity_ordered || 0,
    })),
    status: data.order_status,
    created_at: data.created_at,
  };
};
