<template>
  <div>
    <eden-page-header :title="'Orders'" />
    <el-row type="flex">
      <el-col :span="24">
        <eden-page-stats
          class="my-0"
          :loading="summary.loading"
          :title="'Overview'"
          :stats="stats"
          :show-periods="true"
          :periods="periods"
          @set-period="getOrdersSummary"
        />
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <eden-table-actions :title="title" @search="setQuery">
          <template slot="title"> </template>
          <template slot="actions">
            <el-row type="flex" justify="end" :gutter="20">
              <el-col :span="12">
                <el-button
                  type="plain"
                  icon="eden-icon-upload"
                  class="ml-10"
                  :disabled="loading || !orders.length"
                  @click="showExport = true"
                >
                  Export
                </el-button>
                <orders-export :show.sync="showExport" :orders="orders" />
              </el-col>

              <el-col :span="12">
                <orders-filter
                  :disabled="loading"
                  :clear="filterParams.clear"
                  @filter="filter"
                />
              </el-col>
              <el-col :span="12" v-if="selectedOrders.length > 0">
                <orders-select-actions
                  :orders="selectedOrders"
                  @update="getOrders"
                />
              </el-col>
            </el-row>
          </template>
        </eden-table-actions>
      </el-col>
      <el-col :span="24">
        <eden-loader v-if="loading" />
        <template v-else>
          <eden-filter-items
            v-if="filterParams.status"
            :params="filterParams.paramsLabel"
            @clear-filter="clearFilter"
            @clear-filters="clearFilters"
          />
          <template v-if="orders.length">
            <el-table
              v-if="orders.length"
              :data="orders"
              @select-all="setSelectedOrders"
              @selection-change="setSelectedOrders"
            >
              <el-table-column type="selection" width="45"></el-table-column>
              <el-table-column width="100">
                <template #header>
                  <eden-table-column-header
                    :label="'Order ID'"
                    :property="'id'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'id')"
                  />
                </template>
                <template slot-scope="scope">
                  <span
                    class="font-sm text-primary text-cursor"
                    @click="
                      $refs[`orderActions-${scope.row.id}`].command('view')
                    "
                  >
                    {{ scope.row.id }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column width="200">
                <template #header>
                  <eden-table-column-header
                    :label="'Customer'"
                    :property="'customer_name'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'customer_name')"
                  />
                </template>
                <template slot-scope="scope">
                  <div>
                    <span class="font-sm"> {{ scope.row.customer_name }}</span>
                  </div>
                  <div>
                    <span class="font-sm"> {{ scope.row.customer_email }}</span>
                  </div>
                </template>
              </el-table-column>

              <el-table-column>
                <template #header>
                  <eden-table-column-header
                    :label="'Order Date'"
                    :property="'order_date'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'order_date')"
                  />
                </template>
                <template slot-scope="scope">
                  <span class="font-sm">
                    {{ formatDate(scope.row.order_date, "do m, y") }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column width="100">
                <template #header>
                  <eden-table-column-header
                    :label="'Order Code'"
                    :property="'order_id'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'order_id')"
                  />
                </template>
                <template slot-scope="scope">
                  <span class="font-sm text-primary">
                    {{ scope.row.order_id }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column>
                <template #header>
                  <eden-table-column-header
                    :label="'Phone Number'"
                    :property="'customer_phone_number'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'customer_phone_number')"
                  />
                </template>
                <template slot-scope="scope">
                  <span class="font-sm">
                    {{ scope.row.customer_phone_number }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column>
                <template #header>
                  <eden-table-column-header
                    :label="'Status'"
                    :property="'status'"
                    :sort-property="sort.property"
                    @sort="sortPageData($event, 'status')"
                  />
                </template>
                <template v-slot="scope">
                  <el-tag :type="setType(scope.row.status)">
                    {{ formatToTitleCase(scope.row.status)?.replace("_", " ") }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                v-if="allowAccessFor(['admin', 'operations'])"
                width="80"
              >
                <template v-slot="scope">
                  <order-actions
                    :ref="`orderActions-${scope.row.id}`"
                    :order="scope.row"
                    @update="getOrders"
                  />
                </template>
              </el-table-column>
            </el-table>
            <eden-pagination
              v-if="showPagination"
              :from="pagination.from"
              :to="pagination.to"
              :total="pagination.total"
              :current-page.sync="page"
            />
          </template>
          <eden-content-empty v-else :text="'No orders'" />
        </template>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import OrderActions from "@/components/DanceFestival/Orders/OrderActions.vue";
import OrdersSelectActions from "@/components/DanceFestival/Orders/OrdersSelectActions.vue";
import OrdersFilter from "@/components/DanceFestival/Orders/OrdersFilter.vue";
import { mapOrdersFromBackend } from "@/helpers/api-mappers/dance-festival/orders";
import dfOrders from "@/requests/dance-festival/orders";
import OrdersExport from "@/components/DanceFestival/Orders/OrdersExport.vue";

export default {
  components: {
    OrderActions,
    OrdersSelectActions,
    OrdersFilter,
    OrdersExport,
  },
  data() {
    return {
      loading: false,
      showExport: false,
      exporting: false,
      orders: [],
      period: "today",
      periods: {
        today: "Today",
        lastweek: "Last week",
        thismonth: "This month",
      },
      showPagination: true,
      page: 1,
      pagination: {
        from: null,
        to: null,
        total: null,
      },
      sort: {
        property: "name",
        direction: "asc",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      summary: {
        loading: false,
        data: {},
      },
      selectedOrders: [],
    };
  },
  computed: {
    stats() {
      const summary = this.summary.data;
      return [
        {
          label: "All time orders",
          figure: this.formatFigure(summary.total),
        },
        {
          label: "Total completed orders",
          figure: this.formatFigure(summary.completed_orders),
        },
        {
          label: "Total cancelled orders",
          figure: this.formatFigure(summary.cancelled_orders),
        },
        {
          label: "Pending orders",
          figure: this.formatFigure(summary.pending_orders),
        },
      ];
    },

    title() {
      return (
        this.orders.length + " Order" + (this.orders.length > 1 ? "s" : "")
      );
    },
  },
  created() {
    this.getOrdersSummary({ period: this.period });
    this.getOrders();
  },
  watch: {
    page() {
      this.getOrders();
    },
  },
  methods: {
    getStats({ period, from, to }) {
      console.log(period, from, to);
    },
    getOrdersSummary({ period, from, to }) {
      this.summary.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      dfOrders
        .summary(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.summary.data = data;
            this.period = period;
          }
          this.summary.loading = false;
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getOrders() {
      this.selectedOrders = [];
      const stringedParams =
        `?page=${this.page}&` +
        this.sortObjectAsParams(this.filterParams.params);

      this.loading = true;
      dfOrders
        .list(stringedParams)
        .then(({ data }) => {
          console.log(data.data);
          this.orders = mapOrdersFromBackend(data.data.data);
          this.pagination.from = data.data.from;
          this.pagination.to = data.data.to;
          this.pagination.total = data.data.total;
          this.pagination.pageSize = data.data.per_page;
          this.showPagination = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setQuery(query) {
      if (query === "") {
        this.getOrders();
        return;
      }

      this.loading = true;
      dfOrders
        .search(query)
        .then(({ data }) => {
          this.orders = mapOrdersFromBackend(data.data.data);
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setSelectedOrders(rows) {
      this.selectedOrders = [...rows];
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.orders = this.sortList(this.orders, property, direction);
    },
    filter({ params, paramsLabel }) {
      console.log({ params, paramsLabel });
      const stringedParams = "?" + this.sortObjectAsParams(params) + "&";
      this.page = 1;
      this.filterParams = {
        status: true,
        params: { ...this.filterParams.params, ...params },
        paramsLabel: { ...this.filterParams.paramsLabel, ...paramsLabel },
      };
      this.getOrders(stringedParams);
    },
    clearFilter(key) {
      delete this.filterParams.params[key];
      delete this.filterParams.paramsLabel[key];

      const keys = this.filterParams.params;
      if (Object.keys(keys).length > 1) {
        this.filter({
          params: this.filterParams.params,
          paramsLabel: this.filterParams.paramsLabel,
        });
      } else {
        this.clearFilters();
      }
    },
    clearFilters() {
      this.filterParams = {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      };
      this.getOrders();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  &:not(:last-child) {
    margin-bottom: 50px !important;
  }
}

.meals {
  margin: 0;
  padding-left: 10px;
  list-style-type: circle;
  word-break: break-word;

  li {
    max-width: 250px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  span {
    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
</style>
