import axios from "axios";

export default {
  list(params) {
    return axios.get(`festival/orders${params}`);
  },

  summary(start_date, end_date) {
    return axios.get(
      `festival/orders/summary?start_date=${start_date}&end_date=${end_date}`,
    );
  },

  markAsCompleted(payload) {
    return axios.post(`festival/orders/mark`, payload);
  },

  cancel(id) {
    return axios.get(`festival/orders?id=${id}`);
  },

  search(payload) {
    return axios.get(`festival/orders/search?search=${payload}`);
  },
};
