<template>
  <div>
    <el-dialog
      :title="`Export Food Orders`"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="40% !important"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="key === 'name' || key === 'email'"
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="exporting"
            @click="getExportData"
            :disabled="!form.format"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "OrdersExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orders: {
      type: Array,
      default: () => [],
    },
    dataTime: {
      type: [Date, String],
      default: "",
    },
  },

  data() {
    return {
      fields: {
        name: "Name",
        meal_name: "Meal",
        status: "Status",
        email: "Email",
        phone_number: "Phone number",
        created_at: "Date ordered",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["name", "email"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["name", "email"];
      this.exporting = false;
      this.shouldShow = false;
    },

    getExportData() {
      this.exporting = true;
      this.formatExportData(this.orders);
    },

    formatExportData(data) {
      let updated = this.formatDateAndTime(this.dataTime, "do m, y");
      let date = this.formatDateAndTime(new Date(), "do m, y");
      let title = `Activity Fest Food_Orders  \nDownloaded at - ${updated}\nExported at - ${date}`;
      let headers = {
        sn: "S/N",
      };
      let formattedData = [];
      this.form.fields.forEach((key) => (headers[key] = this.fields[key]));
      formattedData = data?.map((item, index) => {
        return {
          sn: index + 1,
          name: this.formatName(item.customer_name),
          meal_name: this.formatName(item.name),
          email: item.customer_email.toLowerCase(),
          status: item.status,
          phone_number: this.formatPhoneNumber(item.customer_phone_number),
          date_ordered: this.formatDate(item.created_at, "do m, y"),
        };
      });
      this.exportData = {
        title,
        headers,
        data: formattedData,
        fileName: title,
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
