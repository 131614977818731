<template>
  <el-dialog
    title="Mark order status"
    :visible.sync="shouldShow"
    append-to-body
    width="35% !important"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-row type="flex">
        <el-col :span="24">
          <el-form-item
            label="Select the status you want to mark these orders as:"
            prop="order_status"
            :rules="validateField()"
          >
            <el-radio-group v-model="form.order_status">
              <el-radio label="complete">Complete</el-radio>
              <el-radio label="pending">Pending</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button type="primary" :loading="loading" @click="change"
        >Continue</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dfOrders from "@/requests/dance-festival/orders.js";

export default {
  name: "OrderMarkStatusDialog",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    orders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      form: {
        order_status: "",
      },
      loading: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.$refs.form.resetFields();
      this.shouldShow = false;
    },

    change() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        const payload = {
          order_ids: this.orders?.map((item) => item.id),
          order_status: this.form.order_status,
        };
        this.loading = true;

        dfOrders
          .markAsCompleted(payload)
          .then(({ data }) => {
            if (data.status) {
              this.$message.success(data.message);
              this.$emit("success");
              this.closeEvent();
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
